import React, { createContext, useContext, useState } from 'react';

type PortfolioContextType = {
  mode: 'standard' | 'dev';
  setMode: (mode: 'standard' | 'dev') => void;
  isDarkMode: boolean;
  toggleColorMode: () => void;
};

const PortfolioContext = createContext<PortfolioContextType | undefined>(undefined);

export function PortfolioProvider({ children }: { children: React.ReactNode }) {
  const [mode, setMode] = useState<'standard' | 'dev'>('standard');
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleColorMode = () => {
    setIsDarkMode(prev => !prev);
  };

  const value = {
    mode,
    setMode,
    isDarkMode,
    toggleColorMode,
  };

  return (
    <PortfolioContext.Provider value={value}>
      {children}
    </PortfolioContext.Provider>
  );
}

export function usePortfolio() {
  const context = useContext(PortfolioContext);
  if (context === undefined) {
    throw new Error('usePortfolio must be used within a PortfolioProvider');
  }
  return context;
}
