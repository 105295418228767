import { Box, Button, Container, Stack, Typography, IconButton } from "@mui/material";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import DownloadIcon from '@mui/icons-material/Download';
import { usePortfolio } from "../context/PortfolioContext";
import { useNavigate } from "react-router-dom";
import resumePDF from '../assets/resume.pdf';

export default function LandingPage() {
  const { setMode, isDarkMode, toggleColorMode } = usePortfolio();
  const navigate = useNavigate();

  const handlePortfolioChoice = (mode: 'standard' | 'dev') => {
    setMode(mode);
    navigate('/portfolio');
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isDarkMode 
          ? 'linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 100%)'
          : 'linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%)',
        color: isDarkMode ? '#ffffff' : '#000000',
        m: 0,
        p: 0,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.03,
          backgroundImage: 'radial-gradient(circle at 25px 25px, currentColor 2px, transparent 0)',
          backgroundSize: '50px 50px',
        }}
      />

      <IconButton
        onClick={toggleColorMode}
        sx={{
          position: 'absolute',
          top: 20,
          right: 20,
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
          },
          color: isDarkMode ? 'white' : 'inherit',
          backdropFilter: 'blur(5px)',
          border: isDarkMode ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>

      <Stack 
        spacing={4} 
        alignItems="center"
        sx={{
          maxWidth: '800px',
          padding: '0 20px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Typography 
          variant="h4" 
          sx={{
            opacity: 0,
            animation: 'fadeIn 1.2s ease-in forwards',
            '@keyframes fadeIn': {
              '0%': { opacity: 0, transform: 'translateY(-20px)' },
              '100%': { opacity: 1, transform: 'translateY(0)' }
            },
            color: isDarkMode ? '#ffffff' : '#000000',
            letterSpacing: '0.5px',
          }}
        >
          Alyssa Jean
        </Typography>

        <Typography 
          variant="h2" 
          textAlign="center"
          sx={{ 
            color: isDarkMode ? '#ffffff' : '#000000',
            fontWeight: 600,
            letterSpacing: '-0.5px',
          }}
        >
          Welcome to My Portfolio
        </Typography>

        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'center' }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => handlePortfolioChoice('standard')}
            sx={{
              minWidth: 200,
              transition: 'all 0.3s ease',
              borderRadius: '8px',
              background: isDarkMode 
                ? 'linear-gradient(45deg, #2196F3, #1976D2)'
                : 'linear-gradient(45deg, #42a5f5, #1976D2)',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: isDarkMode 
                  ? '0 6px 20px rgba(33, 150, 243, 0.3)'
                  : '0 6px 20px rgba(25, 118, 210, 0.3)',
              }
            }}
          >
            Standard
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => handlePortfolioChoice('dev')}
            sx={{
              minWidth: 200,
              transition: 'all 0.3s ease',
              borderRadius: '8px',
              background: isDarkMode 
                ? 'linear-gradient(45deg, #3f51b5, #303f9f)'
                : 'linear-gradient(45deg, #5c6bc0, #3f51b5)',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: isDarkMode 
                  ? '0 6px 20px rgba(63, 81, 181, 0.3)'
                  : '0 6px 20px rgba(63, 81, 181, 0.3)',
              }
            }}
          >
            Dev
          </Button>
        </Box>

        <Button
          variant="outlined"
          size="medium"
          startIcon={<DownloadIcon />}
          onClick={() => window.open(resumePDF, '_blank')}
          sx={{
            minWidth: 180,
            transition: 'all 0.3s ease',
            borderRadius: '8px',
            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
            color: isDarkMode ? '#ffffff' : '#000000',
            mt: -1,
            '&:hover': {
              transform: 'translateY(-3px)',
              borderColor: isDarkMode ? '#ffffff' : '#000000',
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
            }
          }}
        >
          Download Resume
        </Button>

        <Typography 
          variant="subtitle1" 
          sx={{ 
            mt: 4,
            opacity: 0,
            animation: 'fadeIn 1.5s ease-in forwards 0.5s',
            fontStyle: 'italic',
            color: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
            letterSpacing: '0.3px',
          }}
        >
          Lovin' what I do!
        </Typography>
      </Stack>
    </Container>
  );
}
