import { Global, css } from '@emotion/react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { usePortfolio } from '../context/PortfolioContext';
import StandardLayout from './StandardLayout';
import LandingPage from '../pages/LandingPage';
import DevPortfolio from './DevPortfolio';
import Home from '../pages/Home';
import MDContainer from '../components/MDContainer';
import { pages } from '../pages/pages';

export default function App() {
  const { mode, isDarkMode } = usePortfolio();
  const location = useLocation();

  return (
    <>
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
            min-height: 100vh;
            background: ${isDarkMode ? '#1a1a1a' : '#ffffff'};
          }
          * {
            box-sizing: border-box;
          }
        `}
      />
      
      {location.pathname === '/' || location.pathname === '/home' ? (
        <LandingPage />
      ) : (
        <Routes>
          <Route path="/portfolio" element={mode === 'standard' ? <StandardLayout /> : <DevPortfolio />}>
            <Route index element={<Home setSelectedIndex={() => {}} />} />
            {pages.map(({ index, name, route }) => (
              <Route
                key={index}
                path={route.replace('/', '')}
                element={<MDContainer path={`./pages/${name}`} />}
              />
            ))}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </>
  );
}
