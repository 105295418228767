import { 
  Container, 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery 
} from '@mui/material';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import MDContainer from '../components/MDContainer';
import { pages } from '../pages/pages';
import Home from '../pages/Home';
import Footer from './Footer';
import { usePortfolio } from '../context/PortfolioContext';
import HomeIcon from '@mui/icons-material/Home';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import Brightness4 from '@mui/icons-material/Brightness4';
import Brightness7 from '@mui/icons-material/Brightness7';

export default function StandardLayout() {
  const navigate = useNavigate();
  const { setMode, isDarkMode, toggleColorMode } = usePortfolio();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigation = (route: string) => {
    navigate(`/portfolio${route}`);
    setAnchorEl(null); // Close menu after navigation
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleTheme = () => {
    toggleColorMode();
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
      color: isDarkMode ? '#ffffff' : '#000000'
    }}>
      <AppBar 
        position="static" 
        sx={{
          backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff',
          color: isDarkMode ? '#ffffff' : '#000000'
        }} 
        elevation={1}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ 
            flexGrow: 1,
            color: isDarkMode ? '#ffffff' : '#000000'
          }}>
            {process.env.REACT_APP_NAME}
          </Typography>
          
          <IconButton
            onClick={toggleTheme}
            sx={{
              mr: 2,
              color: isDarkMode ? '#ffffff' : '#000000',
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            {isDarkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          
          {isMobile ? (
            <>
              <IconButton
                sx={{ color: isDarkMode ? '#ffffff' : '#000000' }}
                aria-label="menu"
                onClick={handleMenuOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  sx: {
                    backgroundColor: isDarkMode ? '#2c2c2c' : '#ffffff',
                    color: isDarkMode ? '#ffffff' : '#000000'
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {pages.filter(page => page.visible).map(page => (
                  <MenuItem 
                    key={page.index}
                    onClick={() => handleNavigation(page.route)}
                  >
                    {page.name.replace('.md', '')}
                  </MenuItem>
                ))}
                <MenuItem onClick={() => {
                  navigate('/');
                  handleMenuClose();
                }}>
                  Home
                </MenuItem>
                <MenuItem onClick={() => {
                  setMode('dev');
                  handleMenuClose();
                }}>
                  Dev Mode
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              {pages.filter(page => page.visible).map(page => (
                <Button
                  key={page.index}
                  sx={{ color: isDarkMode ? '#ffffff' : '#000000' }}
                  onClick={() => handleNavigation(page.route)}
                >
                  {page.name.replace('.md', '')}
                </Button>
              ))}
              <Button
                sx={{ 
                  color: isDarkMode ? '#ffffff' : '#000000',
                  ml: 2 
                }}
                onClick={() => navigate('/')}
                startIcon={<HomeIcon />}
              >
                Home
              </Button>
              <Button
                sx={{ 
                  color: isDarkMode ? '#ffffff' : '#000000',
                  ml: 2 
                }}
                onClick={() => setMode('dev')}
                startIcon={<DeveloperModeIcon />}
              >
                Dev Mode
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Container 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          py: 4,
          color: isDarkMode ? '#ffffff' : '#000000'
        }}
      >
        <Routes>
          <Route index element={<Home setSelectedIndex={() => {}} />} />
          {pages.map(({ index, name, route }) => (
            <Route
              key={index}
              path={route.replace('/', '')}
              element={<MDContainer path={`./pages/${name}`} />}
            />
          ))}
          <Route path="*" element={<Navigate to="/portfolio" replace />} />
        </Routes>
      </Container>

      <Footer />
    </Box>
  );
}
